import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {IWorkTimeScheme} from '../../types/work-time-scheme.interface';
import {Reset} from '@ngrx/store-devtools/src/actions';
import {IWorkTimeSchemeFormSubmitValues} from 'src/app/features/employees/components/worktimescheme-form/types/worktimescheme-form-submit-values.interface';
import {ICarryOverRecord} from '../../types/carry-over-record.interface';
import {ICarryOverRecordFormSubmitValues} from 'src/app/features/employees/components/carryoverrecord-form/types/carryoverrecord-form-submit-values.interface';

export const workTimeSchemeActions = createActionGroup({
  source: 'workTimeScheme',
  events: {
    SetWorkTimeScheme: props<{payload: IWorkTimeScheme | null}>(),
    SetCarryOverRecord: props<{payload: ICarryOverRecord | null}>(),
    AddWorkTimeScheme: props<{payload: IWorkTimeSchemeFormSubmitValues}>(),
    AddWorkTimeSchemeSuccess: emptyProps(),
    AddWorkTimeSchemeFailure: emptyProps(),
    UpdateWorkTimeScheme: props<{payload: IWorkTimeSchemeFormSubmitValues}>(),
    UpdateWorkTimeSchemeSuccess: emptyProps(),
    UpdateWorkTimeSchemeFailure: emptyProps(),
    DeleteWorkTimeScheme: props<{payload: number}>(),
    DeleteWorkTimeSchemeSuccess: emptyProps(),
    DeleteWorkTimeSchemeCard: props<{payload: number}>(),
    DeleteWorkTimeSchemeCardSuccess: emptyProps(),
    DeleteWorkTimeSchemeFailure: emptyProps(),
    AddCarryOverRecord: props<{payload: ICarryOverRecordFormSubmitValues}>(),
    AddCarryOverRecordSuccess: emptyProps(),
    AddCarryOverRecordFailure: emptyProps(),
    UpdateCarryOverRecord: props<{payload: ICarryOverRecordFormSubmitValues}>(),
    UpdateCarryOverRecordSuccess: emptyProps(),
    UpdateCarryOverRecordFailure: emptyProps(),
    DeleteCarryOverRecord: props<{payload: number}>(),
    DeleteCarryOverRecordSuccess: emptyProps(),
    DeleteCarryOverRecordFailure: emptyProps(),
    StopLoading: emptyProps(),
    Reset: emptyProps(),
  },
});
